import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import PageHeader from "../components/PageHeader"
import HtmlParser from "../components/HtmlParser"
import { ImageGalleryParagraph } from "../components/Paragraphs/ImageGallery"

const ImageGallery = ({ data }) => (
  <Layout contentType={data.node.internal.type}>
    <Metas title={data.node.title} />
    <PageHeader title={data.node.title} />
    <section className="section page-content">
      <div className="container mx-auto px-4">
        <div className={`grid grid-cols-1 gap-8`}>
          {data.node.body && (
            <div
              className="block content mb-8 lg:px-24"
              data-typesense-field="body"
            >
              <HtmlParser html={data.node.body.value} />
            </div>
          )}

          {data.node.images && (
            <ImageGalleryParagraph node={data.node} title={false} />
          )}
        </div>
      </div>
    </section>
  </Layout>
)

export default ImageGallery

export const query = graphql`
  query ($internalId: Int!) {
    node: nodeAlbumPhoto(
      status: { eq: true }
      drupal_internal__nid: { eq: $internalId }
    ) {
      title
      body {
        summary
        value
      }
      images: field_images {
        alt
        title
      }
      relationships {
        imagesLarge: field_images {
          localFile {
            childImageSharp {
              fluid(maxHeight: 625, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        images: field_images {
          localFile {
            childImageSharp {
              fluid(
                maxWidth: 310
                maxHeight: 310
                cropFocus: CENTER
                quality: 100
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      internal {
        type
      }
    }
  }
`
