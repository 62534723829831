import React from "react"
import PropTypes from "prop-types"

const Title = ({ title, titleClass, subtitle, typesense, ...props }) => {
  if (props.className === undefined) {
    props.className = "page-title"
  } else {
    props.className += " page-title"
  }

  return (
    <div {...props}>
      {subtitle ? (
        <h1 className={`title h1 leading-none ${titleClass}`}>
          <span className="block font-handwritten font-normal text-gray-dark">
            {subtitle}
          </span>
          <span className="uppercase" data-typesense-field="title">
            {title}
          </span>
        </h1>
      ) : (
        <h1 className={`title h1 ${titleClass}`} data-typesense-field="title">
          <span>{title}</span>
        </h1>
      )}
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  subtitle: PropTypes.string,
  typesense: PropTypes.bool,
}

Title.defaultProps = {
  title: null,
  titleClass: null,
  subtitle: null,
  typesense: true,
}

export default Title
