import React from "react"
import PropTypes from "prop-types"
import Breadcrumb from "./Breadcrumb"
import Title from "./Title"

const PageHeader = ({ title, subtitle }) => (
  <section className="section-top page-header relative">
    <div className="container mx-auto px-4">
      <Breadcrumb />
      <Title title={title} titleClass="mb-0 text-primary" subtitle={subtitle} />
    </div>
  </section>
)

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

PageHeader.defaultProps = {
  subtitle: null,
}

export default PageHeader
