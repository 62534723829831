import React, { useContext } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { ModalContext } from "../../contexts/ModalContext"
import { ModalImagesSlideshow } from "../Modal/ModalImagesSlideshow"

export const ImageGalleryParagraph = ({ node, title }) => {
  const { setModalVisibility, setModalContent } = useContext(ModalContext)

  const onClickHandler = index => {
    setModalContent(
      <ModalImagesSlideshow
        active={index}
        images={node.relationships.imagesLarge}
        imagesData={node.images}
      />
    )

    setModalVisibility(true)
  }

  const onKeyDownHandler = (event, index) => {
    if (event.key === "Enter") {
      onClickHandler(index)
    }
  }

  return (
    <div className="paragraph paragraph-image-gallery">
      {title === true && node.title && (
        <h2 className={"title h2 text-primary"}>
          <span>{node.title}</span>
        </h2>
      )}
      <div className={`columns grid grid-cols-2 lg:grid-cols-4 gap-8`}>
        {node.relationships.images.map((image, index) => {
          let imageData = node.images[index]

          return (
            <div key={index} className="column">
              <div
                className="image group relative cursor-pointer bg-secondary overflow-hidden"
                onClick={() => onClickHandler(index)}
                onKeyDown={event => onKeyDownHandler(event, index)}
                tabIndex={0}
                role="button"
              >
                <Img
                  className="transform group-hover:opacity-70 group-hover:scale-110 transition-all duration-500 duration-300"
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={imageData.alt}
                  title={imageData.title}
                />
                <div className="image-caption absolute inset-x-0 top-1/2 p-2 text-center text-white transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-all duration-300">
                  <div className="icon inline-block p-4 w-14 bg-primary text-white rounded-full">
                    <i className="fas fa-search-plus">
                      <span className="hidden">Agrandir</span>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment ImageGalleryParagraph on paragraph__image_gallery {
    id
    title: field_title
    images: field_images {
      alt
      title
    }
    relationships {
      imagesLarge: field_images {
        localFile {
          childImageSharp {
            fluid(maxHeight: 625, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      images: field_images {
        localFile {
          childImageSharp {
            fluid(
              maxWidth: 310
              maxHeight: 310
              cropFocus: CENTER
              quality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
